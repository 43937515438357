import { ChargeTypes } from '@enums/charge-types';
import { AbstractPaymentStoreRequest } from '@models/requests/abstract-payment-store-request.model';

export class PaymentPayLaterStoreRequest extends AbstractPaymentStoreRequest {
  charge_type: string = ChargeTypes.payLater;

  constructor(orderId: string) {
    super(orderId);
  }
}
