import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ChooseAPharmacyComponent } from '@components/consultation-request/choose-a-pharmacy/choose-a-pharmacy.component';
import { ConsultationRequestFormComponent } from '@components/consultation-request/consultation-request-form/consultation-request-form.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { CustomerTestsTypes } from '@enums/customer-tests-types';
import { InputTypes } from '@enums/input-types';
import { QuestionnaireStorageKeys } from '@enums/questionnaire-storage-keys';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';
import { UpdatePharmacyError } from '@errors/update-pharmacy-error';
import { UploadAttachmentError } from '@errors/upload-attachment-error';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { DateData } from '@models/date-data';
import { DateSelection } from '@models/date-selection';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { OrderTest } from '@models/order-test';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { FormService } from '@services/form.service';
import { GenderService } from '@services/gender.service';
import { LoadingService } from '@services/loading.service';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-consultation-request-form',
  templateUrl: './dynamic-consultation-request-form.component.html',
  styleUrls: ['./dynamic-consultation-request-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicConsultationRequestFormComponent
  extends ConsultationRequestFormComponent
  implements OnInit, OnDestroy
{
  @ViewChild('upsellQuestionsForm', { static: false }) private upsellQuestionsForm: DynamicQuestionnaireFormComponent;
  @ViewChild('chooseAPharmacyComponent', { static: false }) private chooseAPharmacyComponent: ChooseAPharmacyComponent;
  @ViewChild('stdPreventionTermsComponent', { static: false })
  private stdPreventionTermsComponent: DynamicQuestionnaireFormComponent;

  QuestionnaireStorageKeys: typeof QuestionnaireStorageKeys = QuestionnaireStorageKeys;
  responseError: string | null = null;
  upsellIds: string[] = [];
  showLabFinderModal: boolean = false;

  private subscriptions: Subscription[] = [];

  /**
   * Determines whether the bottom error message should be displayed.
   */
  get shouldDisplayBottomError(): boolean {
    return this.submittedDataIsIncomplete || !!this.responseError;
  }

  /**
   * Gets the treatment preferences
   */
  get treatmentPreferences(): string {
    return this.treatmentPreferencesControl.value ?? this.sessionStorageService.treatmentPreferences;
  }

  /**
   * Checks if the submitted data is incomplete.
   */
  get submittedDataIsIncomplete(): boolean {
    return (
      (this.consultationRequestForm.invalid ||
        this.treatmentPreferencesForm.invalid ||
        (this.upsellForm && this.upsellQuestionsForm?.form?.invalid) ||
        this.medicalQuestionsForm?.form?.invalid ||
        (this.isStdPrevention && this.stdPreventionTermsComponent?.form?.invalid) ||
        this.medicalHistoryQuestionsForm?.form?.invalid) &&
      this.submissionError !== null
    );
  }

  /**
   * Determines if the consultation request is std prevention type.
   */
  get isStdPrevention(): boolean {
    return this.consultationRequestTreatmentType === ConsultationTreatmentTypes.StdPrevention;
  }

  /**
   * Gets the std prevention terms form group.
   */
  get stdPreventionTermsForm(): FormGroup {
    return this.stdPreventionTermsComponent?.form;
  }

  constructor(
    protected loadingService: LoadingService,
    protected activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected consultationRequestService: ConsultationRequestService,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService,
    protected title: Title,
    protected dynamicFormsService: DynamicFormsService,
    protected errorHandlerService: ErrorHandlerService,
    protected navigationService: NavigationService,
    protected domainService: DomainService,
    protected genderService: GenderService
  ) {
    super(
      consultationRequestService,
      dynamicFormsService,
      errorHandlerService,
      loadingService,
      activatedRoute,
      sessionStorageService
    );
  }

  /**
   * Gets the patient address form group.
   */
  get addressForm(): FormGroup {
    return this.consultationRequestForm.get('address') as FormGroup;
  }

  /**
   * Gets the current consultation request.
   */
  get consultationRequest(): any {
    return this.consultationRequestOrder?.consultationRequest;
  }

  /**
   * Gets the consultation request form group.
   */
  get consultationRequestForm(): FormGroup {
    return this.formService.consultationRequestForm;
  }

  /**
   * Gets the treatment preferences form group.
   */
  get treatmentPreferencesForm(): FormGroup {
    return this.formService.treatmentPreferencesForm;
  }

  /**
   * Gets whether the current consultation request is a UTI consultation request.
   */
  get isUtiTreatmentType(): boolean {
    return this.consultationRequestTreatmentType === ConsultationTreatmentTypes.Uti;
  }

  /**
   * Gets the maximum age allowed for the consultation.
   */
  get maxAge(): number {
    return this.config.maxAgeAllowed;
  }

  /**
   * Gets the minimum age allowed for the consultation.
   */
  get minAge(): number {
    return this.config.order.minAgeAllowed;
  }

  /**
   * Gets the questionnaire form.
   */
  get questionnaireForm(): FormGroup {
    return this.medicalQuestionsForm.form;
  }

  /**
   * Gets the upsell questions form.
   */
  get upsellForm(): FormGroup {
    return this.upsellQuestionsForm?.form;
  }

  /**
   * Gets the external validation data for the dynamic questionnaire form.
   */
  get externalData(): ExternalData {
    return {
      age: this.patientAge,
      gender: this.patientInfoForm.value.gender,
      siteName: `${this.domainService.getSiteDomain()}.com`,
      treatmentName: this.sessionStorageService.getTreatmentPreferenceName(),
    };
  }

  /**
   * Gets the treatment preferences form control.
   */
  get treatmentPreferencesControl(): FormControl {
    return this.treatmentPreferencesForm.get('treatmentPreferences') as FormControl;
  }

  /**
   * Gets the patient's age.
   */
  get patientAge(): number {
    const { day, month, year } = this.patientInfoForm.get('birthday').value;

    return new DateSelection(+month, +day, +year).getAge();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.title.setTitle(this.config.titles.consultationRequest);
    this.consultationRequestService.storeOrderedUpsellsOnSessionStorage();
    this.handleConsultationOrderDetails();
    this.handleStdPreventionConsultation();
  }

  /**
   * Sets the consultation status and unsubscribes from all subscriptions.
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Stores the consultation request data and navigates to the next page.
   */
  continue(): void {
    this.submissionError = null;
    this.responseError = null;

    if (!this.shouldContinue()) {
      this.markAllFormControlsAsTouched();
      this.submissionError = new MissingFormFieldsError().message;

      return;
    }

    this.isProcessing = true;

    if (this.shouldUpdatePharmacy()) {
      return this.updatePharmacy();
    }

    this.handleSuccessWorkflow();
  }

  /**
   * Shows the lab finder modal.
   */
  presentLabFinderModal(): void {
    this.chooseAPharmacyComponent.changingLab = false;
    this.showLabFinderModal = true;
  }

  /**
   * Calculates the difference in days between the current date and the provided date.
   *
   * @param {Date} date the date to compare with the current date
   *
   * @returns {number} the absolute difference in days between the current date and the provided date
   */
  private getDateDiffInDays(date: Date): number {
    const timeDiff = new Date().getTime() - date.getTime();

    return Math.abs(Math.floor(timeDiff / (1000 * 3600 * 24)));
  }

  /**
   * Handles the success of the consultation order details resolver.
   *
   * @param {ConsultationRequestOrderDetail} consultationOrderDetails The consultation order details
   */
  protected handleConsultationDetailsSuccess(consultationOrderDetails: ConsultationRequestOrderDetail): void {
    this.addTreatmentPreferencesValidator();
    this.prefillTreatmentPreferences();
    this.setQuestions().then((questionnaire) => {
      this.upsellIds = questionnaire['upsellIds'];
      this.loadingService.toggleLoader(false);
    });
  }

  /**
   * Perform specific actions for the STD prevention consultation request.
   */
  private handleStdPreventionConsultation(): void {
    if (!this.isStdPrevention) {
      return;
    }

    this.pharmacyForm.valueChanges.subscribe(() => this.consultationRequestForm.updateValueAndValidity());
  }

  /**
   * Sets the treatment preferences form control as required if the consultation request is a UTI consultation request.
   */
  private addTreatmentPreferencesValidator(): void {
    if (this.isUtiTreatmentType) {
      this.treatmentPreferencesControl?.addValidators(Validators.required);
      this.treatmentPreferencesControl?.updateValueAndValidity();
    }
  }

  /**
   * Prefills the treatment preferences with the first ordered test.
   */
  private prefillTreatmentPreferences(): void {
    this.sessionStorageService.treatmentPreferences = this.isStdPrevention
      ? this.config.doxycyclineName
      : this.getFirstOrderedTest()?.customer_tests_name;
  }

  /**
   * Gets the first ordered test.
   *
   * @returns {OrderTest} the first ordered test from consultation request order tests
   */
  private getFirstOrderedTest(): OrderTest {
    return this.consultationRequestOrder?.tests.find(
      ({ customer_tests_type }) => customer_tests_type === CustomerTestsTypes.Individual
    );
  }

  /**
   * Handles the success workflow for the consultation request continue button.
   */
  private handleSuccessWorkflow(): void {
    this.storeConsultationRequestCompletedData();
    this.uploadAttachments().subscribe({
      next: () => {
        this.isProcessing = false;
        this.navigationService.navigateToNextConsultationRequestPage(this.sessionStorageService.treatmentType);
      },
      error: () => {
        this.isProcessing = false;
        this.responseError = new UploadAttachmentError().message;
      },
    });
  }

  /**
   * Uploads attachments from the medical questions form.
   *
   * This method collects file inputs from the form and uploads them. If there are no files to upload, it returns an
   * empty observable.
   *
   * @returns {Observable<any[]>} an observable that emits an array of responses for each uploaded file
   */
  private uploadAttachments(): Observable<any[]> {
    const observables = this.medicalQuestionsForm.questions
      .filter(({ type }) => type === InputTypes.File)
      .map(({ id }) => {
        const file = this.medicalQuestionsForm.form.get(id).value;
        if (!file) {
          return null;
        }
        return this.createAttachmentObservable(file);
      })
      .filter(Boolean); // Remove null values
    return observables.length ? forkJoin(observables) : of([]);
  }
  /**
   * Creates an observable for uploading a file attachment.
   *
   * @param {File} file the file to be uploaded
   *
   * @returns {Observable<any>} an observable that emits the response of the upload operation
   */
  private createAttachmentObservable(file: File): Observable<any> {
    return this.consultationRequestService.storeAttachment(
      this.sessionStorageService.transactionId,
      this.sessionStorageService.hash,
      this.sessionStorageService.consultationId,
      file
    );
  }

  /**
   * Determines if it has been less than 28 days since the last treatment was prescribed.
   *
   * @returns {boolean} true if it has been less than 28 days, otherwise false
   */
  private isLessThan28DaysSinceLastTreatment(): boolean {
    const lastPrescribedAt = this.consultationRequestOrder.last_prescribed_at;
    if (!lastPrescribedAt) {
      return false;
    }

    return this.getDateDiffInDays(new Date(lastPrescribedAt)) < 28;
  }

  /**
   * Determines if a patient's age makes them eligible for a consultation request.
   *
   * @param {DateData} patientBirthday the patient's date of birth
   *
   * @returns {boolean} true if the patient's age is within the valid range, otherwise false
   */
  private isPatientAgeValid(patientBirthday: DateData): boolean {
    const { day, month, year } = patientBirthday;

    return new DateSelection(+month, +day, +year).isAgeValid(this.minAge, this.maxAge);
  }

  /**
   * Marks all form controls as touched.
   */
  private markAllFormControlsAsTouched(): void {
    this.patientInfoForm.markAllAsTouched();
    this.pharmacyForm.markAllAsTouched();
    this.treatmentPreferencesControl.markAllAsTouched();
    this.addressForm.markAllAsTouched();
    this.questionnaireForm.markAllAsTouched();
    this.upsellForm?.markAllAsTouched();
    this.medicalHistoryForm.markAllAsTouched();
    this.stdPreventionTermsForm?.markAllAsTouched();
  }

  /**
   * Sets the consultation status to disqualified if the patient is not eligible for the consultation.
   */
  protected getConsultationStatus(): ConsultationStatus {
    return this.isLessThan28DaysSinceLastTreatment() ||
      !this.genderService
        .getAllowedTreatmentTypeGenders(this.sessionStorageService)
        .includes(this.sessionStorageService.patient.gender) ||
      !this.isPatientAgeValid(this.sessionStorageService.patient.birthday) ||
      this.hasDisqualifyingAnswers()
      ? ConsultationStatus.Disqualified
      : ConsultationStatus.Pending;
  }

  /**
   * Checks if the forms displayed on the page are valid.
   *
   * @returns {boolean} true if all forms are valid, otherwise false
   */
  private shouldContinue(): boolean {
    return (
      this.medicalHistoryForm.valid &&
      this.questionnaireForm.valid &&
      this.patientInfoForm.valid &&
      this.pharmacyForm.valid &&
      this.treatmentPreferencesControl.valid &&
      this.addressForm.valid &&
      (!this.upsellForm || this.upsellForm.valid) &&
      (!this.stdPreventionTermsForm || this.stdPreventionTermsForm.valid)
    );
  }

  /**
   * Checks if the pharmacy should be updated.
   *
   * @returns {boolean} true if the pharmacy should be updated, otherwise false
   */
  private shouldUpdatePharmacy(): boolean {
    return this.consultationRequest.pharmacy?.id !== this.pharmacyForm.value.id;
  }

  /**
   * Stores the data that is needed for the consultation request completed page.
   */
  protected storeConsultationRequestCompletedData(): void {
    super.storeConsultationRequestCompletedData();
    this.sessionStorageService.address = this.addressForm.value;
    this.sessionStorageService.upsells = this.getUpsells();

    if (this.sessionStorageService.upsells.includes(UpsellSlugs.PartnerTreatment)) {
      this.sessionStorageService.partnerEmail = this.sessionStorageService.getQuestionnaireAnswers(
        QuestionnaireStorageKeys.Upsells
      )[this.config.partnerEmailQuestionId];
    }
  }

  /**
   * Gets the upsells selected by the patient.
   */
  private getUpsells(): string[] {
    const upsellAnswers = this.sessionStorageService.getQuestionnaireAnswers(QuestionnaireStorageKeys.Upsells);
    if (!upsellAnswers) {
      return [];
    }
    const availableUpsells = Object.values(UpsellSlugs) as string[];

    return Object.values(upsellAnswers).filter((value: string) => availableUpsells.includes(value));
  }

  /**
   * Updates the consultation request pharmacy.
   */
  private updatePharmacy(): void {
    this.consultationRequestService
      .update(
        this.consultationRequest.id,
        this.consultationRequestOrder.transaction_id,
        this.consultationRequestOrder.hash,
        {
          pharmacy_id: this.pharmacyForm.value.id,
        }
      )
      .subscribe({
        next: () => this.handleSuccessWorkflow(),
        error: () => {
          this.isProcessing = false;
          this.responseError = new UpdatePharmacyError().message;
        },
      });
  }
}
