import { AbstractPaymentStoreRequest } from '@models/requests/abstract-payment-store-request.model';

export class PaymentBraintreeStoreRequest extends AbstractPaymentStoreRequest {
  nonce: string;

  constructor(orderId: string, chargeAmount: number, nonce: string) {
    super(orderId, chargeAmount);
    this.nonce = nonce;
  }
}
