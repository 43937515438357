// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 847px) {
  app-lab-finder-modal .modal {
    justify-content: flex-end;
  }
  app-lab-finder-modal .modal-content {
    background-color: white;
    padding-bottom: 12px;
    height: calc(100dvh - 80px);
    max-height: calc(100dvh - 80px);
  }
  app-lab-finder-modal .findALabContainer.findalab-container-mobile {
    height: calc(100dvh - 104px) !important;
    justify-content: flex-end;
  }
  app-lab-finder-modal .findALabContainer.findalab-container-mobile .findALabMap {
    height: inherit !important;
  }
  app-lab-finder-modal .findALabControls.findalab-controls-mobile {
    max-height: calc(100dvh - 104px);
    flex-grow: 1;
  }
  app-lab-finder-modal .findALabControls.findalab-controls-mobile findalab-results {
    overflow: auto;
    border-radius: 6px;
  }
  app-lab-finder-modal .findALabControls.findalab-controls-mobile .resultsContainer {
    height: 100% !important;
    box-shadow: none;
  }
}

@media screen and (max-width: 847px) {
  app-treatmyuti-layout app-lab-finder-modal .findALabControls.findalab-controls-mobile findalab-results {
    box-shadow: 0px 0px 5px #b46397;
  }
  app-stdcheck-layout app-lab-finder-modal .findALabControls.findalab-controls-mobile findalab-results {
    box-shadow: 0px 0px 5px #5b90b3;
  }
  app-starfish-layout app-lab-finder-modal .findALabControls.findalab-controls-mobile findalab-results {
    box-shadow: 0px 0px 5px #fa676b;
  }
  app-healthlabs-layout app-lab-finder-modal .findALabControls.findalab-controls-mobile findalab-results {
    box-shadow: 0px 0px 5px #43ac6a;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
