import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Address } from '@models/address';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { MapsLoadService } from '@services/maps-load.service';

@Component({
  selector: 'app-address-inputs',
  templateUrl: './address-inputs.component.html',
  styleUrls: ['./address-inputs.component.scss'],
})
export class AddressInputsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('streetAddress', { static: false }) public streetAddressElement: ElementRef;
  @Input() form: FormGroup;
  @Input() multiregion: boolean = true;
  Address: typeof Address = Address;
  private streetAddressAutoCompleteInstance: google.maps.places.Autocomplete;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private formService: FormService,
    private mapsLoadService: MapsLoadService,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.initAddressAutoComplete();

    if (!this.multiregion) {
      this.setUSAddressZipCodeValidators();
    }
  }

  /**
   * Cleans up subscriptions when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.removeAddressAutoCompleteListener();
  }

  /**
   * Gets the selected state from the form.
   *
   * @return {string} the selected state
   */
  get selectedState(): string {
    return this.form?.get('state')?.value;
  }

  /**
   * Gets the input type for the zip code field according to the selected state.
   *
   * @return {'tel' | 'text'} the input type for the zip code field
   */
  get zipCodeInputType(): 'tel' | 'text' {
    if (this.stateBelongsToCountry(this.selectedState, 'US')) {
      return 'tel';
    }

    return 'text';
  }

  /**
   * Gets the placeholder for the zip code field according to the selected state.
   *
   * @return {'Zip Code' | 'Postal Code'} the placeholder for the zip code field
   */
  get zipCodePlaceholder(): 'Zip Code' | 'Postal Code' {
    if (this.stateBelongsToCountry(this.selectedState, 'CA')) {
      return 'Postal Code';
    }

    return 'Zip Code';
  }

  /**
   * Determines if a form field is invalid.
   *
   * @param field string The name of the field to check vor validity.
   */
  isInvalid(field: string = null): boolean {
    let input = field ? this.form?.get(field) : this.form;

    return input?.invalid && input.touched;
  }

  /**
   * Determines if a form field is failing a specific validation.
   *
   * @param errorName name of the validation that could be giving error
   * @param field the name of the field to check for specific validation error
   *
   * @returns true if the field has the specific error, false otherwise
   */
  hasSpecificError(errorName: string, field: string): boolean {
    const input = this.form.get(field);

    return !!input?.errors?.[errorName];
  }

  /**
   * Remove the class in the body that hide Google Maps address autocomplete
   */
  showGoogleMapsAddressAutocomplete() {
    this.renderer.removeClass(document.body, 'hide-gm-address-autocomplete');
  }

  /**
   * Adds the class in the body that hide Google Maps address autocomplete
   */
  hideGoogleMapsAddressAutocomplete() {
    this.renderer.addClass(document.body, 'hide-gm-address-autocomplete');
  }

  /**
   * Initializes google autocomplete.
   */
  private initAddressAutoComplete(): void {
    this.hideGoogleMapsAddressAutocomplete();
    this.mapsLoadService.getGoogleMapApi(this.config.googleMapApiKey).subscribe({
      next: (googleMaps) => {
        this.streetAddressAutoCompleteInstance = new googleMaps.places.Autocomplete(
          this.streetAddressElement.nativeElement,
          { fields: ['address_components'] }
        );
        this.streetAddressAutoCompleteInstance.addListener('place_changed', this.selectLocation.bind(this));
      },
      error: (err) => console.error(err),
    });
  }

  /**
   * Handles the place changed event.
   */
  private selectLocation(): void {
    try {
      this.fillAddress(this.getAddressFromSelectedPlace());
    } catch (e) {
      const streetAddressControl = this.form.get('streetAddress');
      streetAddressControl.setValue('');
      console.warn(e);
    }
  }

  /**
   * Removed the autocomplete listener.
   */
  private removeAddressAutoCompleteListener(): void {
    if (window.google) {
      google.maps.event.clearInstanceListeners(this.streetAddressElement.nativeElement);
    }
  }

  /**
   * Fills the address form out with the given address.
   *
   * @param address Address The address to fill the form with.
   */
  private fillAddress(address: Address): void {
    for (let prop in address) {
      if (Object.prototype.hasOwnProperty.call(address, prop)) {
        this.form.get(prop).setValue(address[prop]);
      }
    }

    this.form.markAllAsTouched();
  }

  /**
   * Gets the address from the selected place.
   */
  private getAddressFromSelectedPlace(): Address {
    const state = this.getAddressPartFromSelectedPlace(['administrative_area_level_1']);

    return new Address({
      streetAddress:
        this.getAddressPartFromSelectedPlace(['street_number']) + ' ' + this.getAddressPartFromSelectedPlace(['route']),
      city: this.getAddressPartFromSelectedPlace(['locality', 'sublocality']),
      state: this.isValidState(state) ? state : null,
      zipcode: this.getAddressPartFromSelectedPlace(['postal_code']),
    });
  }

  /**
   * Get the specified part of the selected address.
   *
   * @param {string[]} partNames The names of the part of the address to get.
   */
  private getAddressPartFromSelectedPlace(partNames: string[]): string {
    const place: google.maps.places.PlaceResult = this.streetAddressAutoCompleteInstance.getPlace();

    if (place.address_components.length < 1) {
      throw `No address components found for ${this.form.get('streetAddress')}`;
    }

    const matchedComponents = place.address_components.filter((addrComponent) =>
      addrComponent.types.some((type) => partNames.includes(type))
    );

    if (matchedComponents && matchedComponents.length < 1) {
      throw `Address components '${partNames.concat(', ')}' not found in ${JSON.stringify(place)}`;
    }

    return matchedComponents[0].short_name;
  }

  /**
   * Checks if a given state belongs to a specific country.
   *
   * @param {string} state the abbreviation of the state to check
   * @param {'US' | 'CA'} country the country code ('US' for United States, 'CA' for Canada)
   *
   * @returns {boolean} true if the state belongs to the specified country, otherwise false
   */
  private stateBelongsToCountry(state: string, country: 'US' | 'CA'): boolean {
    return Address[`states${country}`].some((countryState) => countryState.abbv === state);
  }

  /**
   * Alter the validators for the address zip code field to only US.
   */
  private setUSAddressZipCodeValidators(): void {
    this.form?.get('zipcode').clearValidators();
    this.form?.get('zipcode').setValidators([Validators.required, Validators.pattern('^[0-9]{5}$')]);
    this.form?.get('zipcode').updateValueAndValidity();
  }

  /**
   * Verifies if the provided state abbreviation is valid within the allowed regions.
   *
   * @param {string} state the abbreviation of the state to validate
   *
   * @returns {boolean} true if the state abbreviation is valid, otherwise false
   */
  private isValidState(state: string): boolean {
    const usStates = Address.statesUS.map((state) => state.abbv);
    const caStates = Address.statesCA.map((state) => state.abbv);

    return this.multiregion ? usStates.includes(state) || caStates.includes(state) : usStates.includes(state);
  }
}
