import { ChargeTypes } from '@enums/charge-types';
import { PaymentTypes } from '@enums/payment-types';
import { AbstractPaymentStoreRequest } from '@models/requests/abstract-payment-store-request.model';

export class PaymentCreditCardStoreRequest extends AbstractPaymentStoreRequest {
  charge_type: ChargeTypes = ChargeTypes['Credit Card'];
  card_number: string;
  card_exp: Date;
  billing_zip_code: string;
  card_holder: string;
  cvv: string;

  constructor(orderId: string, paymentAmount: number, creditCardFormValues: any, creditCardHolder: string) {
    super(orderId, paymentAmount);
    this.setCreditCardInfo(creditCardFormValues);
    this.card_holder = creditCardHolder;
  }

  /**
   * Sets credit card information to the request.
   *
   * @param creditCardInfo The credit info from the payment form
   */
  private setCreditCardInfo(creditCardInfo: any): void {
    this.charge_type = ChargeTypes[PaymentTypes.CreditCard];
    this.card_number = creditCardInfo.cardNumber;
    this.card_exp = new Date(creditCardInfo.cardYear, +creditCardInfo.cardMonth - 1);
    this.billing_zip_code = creditCardInfo.billingZipCode;
    this.cvv = creditCardInfo.cvv;
  }
}
