import { Component } from '@angular/core';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-relieve-outbreak-pain',
  templateUrl: './relieve-outbreak-pain.component.html',
  styleUrl: './relieve-outbreak-pain.component.scss',
})
export class RelieveOutbreakPainComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.LidocaineAmitriptylineCream;

  constructor() {}
}
