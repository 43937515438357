import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormHelper } from '@common/form-helper';
import { Month } from '@common/month';
import { Months } from '@common/months';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DataLayerService } from '@services/data-layer.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { ScheduleOnceService } from '@services/schedule-once.service';
import { SessionStorageService } from '@services/session-storage.service';
import { catchError, map, Observable, of, Subscription, take } from 'rxjs';

@Component({
  selector: 'app-std-consultation-request-form',
  templateUrl: './std-consultation-request-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class StdConsultationRequestFormComponent extends FormHelper implements OnInit, OnDestroy {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;
  calendarOptions: { months: Month[]; days: number[]; years: number[] };
  formGroupsToBeValidated = ['personal', 'contact', 'medicalHistory', 'prescription'];
  freeEnabled: boolean = false;
  positiveTestsSlugs: string[] = ['other'];

  private params: Params;
  private subscriptions: Subscription[] = [];
  private userId: number = null;

  /**
   * Get the FormGroup for the pharmacy section
   */
  get pharmacyForm(): FormGroup {
    return this.consultationRequestService.consultationForm.get('pharmacy') as FormGroup;
  }

  constructor(
    public consultationRequestService: ConsultationRequestService,
    private activatedRoute: ActivatedRoute,
    private braintreeService: BraintreeService,
    private dataLayerService: DataLayerService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private scheduleOnceService: ScheduleOnceService,
    private sessionStorageService: SessionStorageService
  ) {
    super();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.setCalendarOptions();
    this.subscriptions.push(this.listenForQueryParamsChanges());
    this.setPositives();
    this.checkManageUser();
  }

  /**
   * Unsubscribes from all subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Determines if any of the positive tests include a disease for which the partner can be treated.
   *
   * @returns {boolean} true if the user tested positive for any of the partnerTreatmentSTDs, otherwise false
   */
  get showPartner(): boolean {
    return (
      this.sessionStorageService.treatmentType !== ConsultationTreatmentTypes.Nurse &&
      this.positiveTestsSlugs.filter((test) =>
        ConsultationRequestService.partnerTreatmentSTDs.some((std) => test.includes(std))
      ).length > 0
    );
  }

  /**
   * Gets the consultation service cost in USD.
   *
   * @returns {number} the consultation service cost in USD
   */
  get consultationServiceCostInUsd(): number {
    return ConsultationRequestService.cost / 100;
  }

  /**
   * Places a consultation request order.
   */
  submitConsultationRequest(): void {
    this.placeOrderComponent.processing = true;
    this.consultationRequestService
      .submitConsultationRequest(this.positiveTestsSlugs, this.userId, this.braintreeService.deviceData)
      .subscribe({
        next: (response) => this.handleConsultationRequestSuccess(response),
        error: (error) => this.handleConsultationRequestError(error),
      });
  }

  /**
   * Handles a successful consultation request submission by adding purchase on data layer and redirect to next page.
   *
   * @param {any} consultationRequestResponse the response from the consultation request submission
   */
  private handleConsultationRequestSuccess(consultationRequestResponse: any): void {
    this.placeOrderComponent.processing = false;
    this.addConsultationRequestPurchaseToDataLayer(consultationRequestResponse);
    consultationRequestResponse.schedule_once_id
      ? this.redirectToScheduleOncePage(consultationRequestResponse)
      : this.redirectToCompletedPage();
  }

  /**
   * Handles an error that occurred during the consultation request submission.
   *
   * @param {HttpErrorResponse} error the error that occurred during the consultation request submission
   */
  private handleConsultationRequestError(error: HttpErrorResponse): void {
    this.placeOrderComponent.submissionErrors = this.errorHandlerService.handleResponseError(error);
    this.placeOrderComponent.processing = false;
  }

  /**
   * Adds the consultation request purchase to the data layer.
   *
   * @param {any} consultationRequestResponse the response from the consultation request submission
   */
  private addConsultationRequestPurchaseToDataLayer(consultationRequestResponse: any): void {
    this.dataLayerService.addConsultationRequestPurchaseToDataLayer(
      consultationRequestResponse,
      ConsultationTreatmentTypes.Std,
      this.consultationRequestService.consultationOrderDetail.transaction_id
    );
  }

  /**
   * Redirects to the ScheduleOnce page.
   *
   * @param {any} consultationRequestResponse the response from the consultation request submission
   */
  private redirectToScheduleOncePage(consultationRequestResponse: any): void {
    const formValue = this.consultationRequestService.consultationForm.value;

    this.router.navigateByUrl(
      this.scheduleOnceService.getScheduleConsultationUrl(
        consultationRequestResponse.consultation_request_id,
        consultationRequestResponse.schedule_once_id,
        this.params.order_id,
        this.params.hash,
        formValue.contact.phone.replace(/[^0-9]/g, '')
      )
    );
  }

  /**
   * Redirects to the consultation request completed page.
   */
  private redirectToCompletedPage(): void {
    this.router.navigateByUrl(`/${ConsultationTreatmentTypes.Std}/consultation-request-completed`);
  }

  /**
   * Sets the date of birth select options.
   */
  private setCalendarOptions(): void {
    this.calendarOptions = {
      months: Months,
      days: this.getDays(),
      years: this.getYears(),
    };
  }

  /**
   * Gets a list of all the days
   *
   * @returns {number[]} a list of numbers from 1 to 31
   */
  private getDays(): number[] {
    return this.getListOfNumbers(1, 31);
  }

  /**
   * Gets a list of the last 90 years.
   *
   * @returns {number[]} a list of numbers from the current year to 1920
   */
  private getYears(): number[] {
    const currentYear = new Date().getFullYear();

    return this.getListOfNumbers(currentYear, 1920);
  }

  /**
   * Listens for changes in URL query params to set the userId variable.
   *
   * @returns {Subscription} a Subscription to changes in the URL query params
   */
  private listenForQueryParamsChanges(): Subscription {
    return this.activatedRoute.queryParams.subscribe((params) => (this.params = params));
  }

  /**
   * Creates an array with the slugs of the tests for which the person tested positive.
   */
  private setPositives(): void {
    this.consultationRequestService.getConsultationRequestPositives().subscribe({
      next: (positives) => {
        const positiveSlugs = this.consultationRequestService.consultationOrderDetail.lab_order_tests
          .filter(
            (labOrderTest) =>
              ['abnormal', 'detected'].includes(labOrderTest.result_status) &&
              positives.some((positive) => positive.name === labOrderTest.test.name)
          )
          .map((labOrderTest) => labOrderTest.test.slug);

        if (positiveSlugs.length === 0) {
          positiveSlugs.push('other');
        }

        this.positiveTestsSlugs = positiveSlugs;
      },
      error: () => (this.positiveTestsSlugs = ['other']),
    });
  }

  /**
   * Enables free order if the manage ID provided is valid.
   */
  private checkManageUser(): void {
    const manageUserId = this.getManagerUserId();

    if (!manageUserId) {
      return;
    }

    this.validateUserId(manageUserId)
      .pipe(take(1))
      .subscribe((isValid) => {
        if (isValid) {
          this.userId = manageUserId;
          this.freeEnabled = true;
        }
      });
  }

  /**
   * Gets the decoded manager user ID.
   *
   * @returns {null | number} the decoded manager user ID
   */
  private getManagerUserId(): null | number {
    if (this.params?.user_id) {
      return this.decodeUserId(this.params?.user_id);
    }

    return null;
  }

  /**
   * Validates the user ID.
   *
   * @param {number} userId the manager user ID
   *
   * @return {Observable<boolean>} an Observable that emits true if the user ID is valid, false otherwise
   */
  private validateUserId(userId: number): Observable<boolean> {
    return this.consultationRequestService.getManageUser(userId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  /**
   * Decodes the base64 manager user ID.
   *
   * @param {string} userId the encode manager user ID
   *
   * @returns {null | number} the decoded manager user ID or null if the decoding fails
   */
  private decodeUserId(userId: string): null | number {
    try {
      return parseInt(atob(userId));
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}
