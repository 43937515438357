import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { OrderUpsell } from '@models/order-upsell';
import { Recharge } from '@models/recharge';
import { PaymentRechargeBraintreeStoreRequest } from '@models/requests/payment-recharge-briantree-store-request.model';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';
import { SessionStorageService } from '@services/session-storage.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';
import { from, map, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultationUpsellPaymentGuard implements CanActivate {
  constructor(
    private navigationService: NavigationService,
    private orderService: OrderService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService
  ) {}

  /**
   * Determines whether the current route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route the route to be activated
   * @param {RouterStateSnapshot} state the current router state
   *
   * @returns {Observable<boolean | UrlTree>} an observable that emits true if the route can be activated, or a UrlTree
   * to redirect otherwise
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const orderUpsells = this.treatmentConsultationQuestionnaireService.getUnorderedUpsells();
    if (!orderUpsells?.length) {
      return from(this.navigateToNextPage(state));
    }

    const recharge = this.sessionStorageService.recharge;
    if (!recharge) {
      return of(true);
    }

    return this.submitUpsells(orderUpsells, recharge).pipe(
      switchMap(() => {
        if (this.sessionStorageService.upsells.includes(UpsellSlugs.PartnerTreatment)) {
          return this.orderService
            .storePartnerEmail(
              this.sessionStorageService.partnerEmail,
              this.sessionStorageService.transactionId,
              this.sessionStorageService.hash
            )
            .pipe(
              switchMap(() => from(this.navigateToNextPage(state))),
              catchError(() => from(this.navigateToNextPage(state)))
            );
        }

        return from(this.navigateToNextPage(state));
      }),
      catchError(() => of(true))
    );
  }

  /**
   * Navigates to the next page in the consultation flow based on the current treatment type and URL.
   *
   * @param {RouterStateSnapshot} state the current router state
   *
   * @returns {Promise<UrlTree>} a UrlTree representing the next page URL
   */
  private async navigateToNextPage(state: RouterStateSnapshot): Promise<UrlTree> {
    const nextPageUrl = await this.navigationService.getConsultationRequestNextPageUrl(
      this.sessionStorageService.treatmentType,
      state.url
    );

    return this.router.createUrlTree([nextPageUrl]);
  }

  /**
   * Submits the upsells associated with the current consultation.
   *
   * @param {OrderUpsell[]} orderUpsells the upsells to be submitted
   * @param {Recharge}      recharge     the recharge object associated with the current consultation
   * @returns {Observable<void>} an observable that completes when the upsells are submitted
   */
  private submitUpsells(orderUpsells: OrderUpsell[], recharge: Recharge): Observable<void> {
    const upsellChargeAmount = orderUpsells.reduce((acc, upsell) => acc + upsell.upsell_price, 0);
    const paymentRequest = new PaymentRechargeBraintreeStoreRequest(
      this.sessionStorageService.transactionId,
      upsellChargeAmount,
      recharge.vault_id
    );

    return this.orderService.submitUpsells(orderUpsells, paymentRequest, this.sessionStorageService.hash).pipe(
      map((response) => {
        if (response?.transaction_id) {
          this.sessionStorageService.upsell = [response.transaction_id];
        }
      })
    );
  }
}
