import { NgComponentOutlet } from '@angular/common';
import { Component, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsultationRequestComponent } from '@components/consultation-request/consultation-request.component';
import { ConsultationRequestFormComponent } from '@components/consultation-request/consultation-request-form/consultation-request-form.component';
import { DynamicConsultationRequestFormComponent } from '@components/consultation-request/dynamic-consultation-request-form/dynamic-consultation-request-form.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-consultation-page',
  standalone: true,
  imports: [NgComponentOutlet],
  template: `<ng-container *ngComponentOutlet="getConsultationComponent()" />`,
})
export class ConsultationRequestPageComponent {
  constructor(private sessionStorage: SessionStorageService, private route: ActivatedRoute) {}

  /**
   * Gets the consultation component based on the treatment type
   *
   * @returns {Type<ConsultationRequestFormComponent | ConsultationRequestComponent>} the consultation component
   */
  getConsultationComponent(): Type<ConsultationRequestFormComponent | ConsultationRequestComponent> {
    switch (this.sessionStorage.treatmentType) {
      case ConsultationTreatmentTypes.Nurse:
      case ConsultationTreatmentTypes.Std:
        return ConsultationRequestComponent;
      default:
        return DynamicConsultationRequestFormComponent;
    }
  }
}
