import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Question } from '@models/dynamic-forms/question';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  standalone: true,
  imports: [ReactiveFormsModule],
})
export class TextInputComponent {
  @Input() form: FormGroup;
  @Input() question: Question;
}
