import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { PaymentTypes } from '@enums/payment-types';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';
import { LegacyUpsellStoreResponse } from '@models/legacy-upsell-store-response.model';
import { OrderUpsell } from '@models/order-upsell';
import { AddressUpdateRequest } from '@models/requests/address-update.model';
import { LegacyUpsellStoreRequest } from '@models/requests/legacy-upsell-store.model';
import { Upsell } from '@models/upsell';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DataLayerService } from '@services/data-layer.service';
import { DomainService } from '@services/domain.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { FormService } from '@services/form.service';
import { LoadingService } from '@services/loading.service';
import { MycoplasmaGenitaliumService } from '@services/mycoplasma-genitalium.service';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-order-address',
  templateUrl: './order-address.component.html',
  styleUrls: ['./order-address.component.scss'],
})
export class OrderAddressComponent implements OnInit {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;

  addressContactInformation: FormGroup;
  upsellTestsForm: FormGroup;
  public tos: string;
  domain = 'STDcheck';
  upsellTests: OrderUpsell[];
  processing: boolean = false;
  upsellPaymentFailed: boolean = false;
  submittedForm: boolean = false;
  submissionErrors: string[] = [];
  upsellSelected: OrderUpsell;
  incompleteFormErrorMessage = new MissingFormFieldsError().message;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private domainService: DomainService,
    private orderService: OrderService,
    private dataLayerService: DataLayerService,
    private storageService: StorageService,
    private router: Router,
    private loadingService: LoadingService,
    private errorHandlerService: ErrorHandlerService,
    private changeDetectorRef: ChangeDetectorRef,
    private mycoplasmaGenitaliumService: MycoplasmaGenitaliumService,
    private navigationService: NavigationService
  ) {}

  /**
   * Determines if the upsell payment form should be displayed.
   */
  get displayPaymentInfoCard(): boolean {
    return Boolean(this.upsellPaymentFailed && this.upsellTestsForm && this.upsellSelected);
  }

  /**
   * Gets the address form.
   */
  get addressForm(): FormGroup {
    return this.addressContactInformation.get('address') as FormGroup;
  }

  /**
   * Initializes component features.
   */
  ngOnInit(): void {
    this.loadRecommendedTests();
    this.initAddressForm();
    this.reOrderAddressForm();
    this.dataLayerService.handleDataLayer();
    this.domain = this.config.domain;
  }

  /**
   * Initializes the address form.
   */
  private initAddressForm(): void {
    if (this.isPhoneOrder) {
      this.formService.addressContactInformation.removeControl('phone');
      this.formService.addressContactInformation.addControl('phone', this.formService.orderPhoneForm());
    }

    this.addressContactInformation = this.formService.addressContactInformation as FormGroup;
  }

  /**
   * Initializes the additional recommended testing form.
   */
  private initUpsellForm(): void {
    this.upsellTestsForm = this.formBuilder.group({
      tests: new FormGroup({}),
      payment: this.formService.getNewPaymentForm(),
    });

    this.upsellTests.forEach((upsellTest) => {
      (this.upsellTestsForm.controls.tests as FormGroup).addControl(
        upsellTest.slug,
        new FormControl(upsellTest.id?.toString() === this.orderService.reorderData?.upsell?.test_id?.toString())
      );
    });
  }

  /**
   * Creates an object with the name of the upsell test
   *
   * @returns an object with the name of the selected upsell test
   */
  private getSelectedUpsellTest(): Upsell | null {
    if (!this.upsellTests || !this.upsellTestsForm || !this.upsellSelected) {
      return null;
    }

    return new Upsell(this.upsellSelected.name);
  }

  /**
   * Submits the form.
   */
  submit(): void {
    this.updatePatientPhoneInStorageIfNonEmpty();

    if (this.storageService.addressComplete) {
      this.processing = true;
      this.continueOrderingWorkflow();

      return;
    }

    this.addressContactInformation.markAllAsTouched();
    this.submittedForm = true;
    if (this.addressContactInformation.invalid) {
      return;
    }

    const addressUpdateRequest = new AddressUpdateRequest(this.addressContactInformation);

    if (this.addressContactInformation.get('phone.sms_allowed').value) {
      this.storageService.sendSMS = true;
    }

    this.processing = true;
    this.submissionErrors = [];
    this.orderService.storeAddress(addressUpdateRequest).subscribe({
      next: this.handleSuccessfulOrderAddressUpdate.bind(this),
      error: this.handleRequestError.bind(this),
    });
  }

  /**
   * Determines if the control is invalid and touched.
   *
   * @param {AbstractControl} control the control to be checked
   *
   * @returns true if the control is invalid and touched, false otherwise
   */
  isInvalid(control: AbstractControl): boolean {
    return control?.invalid && control?.touched;
  }

  /**
   * Render the reorder data for Patient address
   */
  reOrderAddressForm(): void {
    const reorderData = this.orderService.reorderData;
    if (!reorderData) {
      return;
    }

    this.addressContactInformation.patchValue({
      phone: {
        number: reorderData.order_phone,
        voicemail_allowed: reorderData.leave_msg && reorderData.leave_msg === '1',
        sms_allowed: reorderData.send_sms,
      },
      address: {
        streetAddress: reorderData.address?.customer_address_street,
        city: reorderData.address?.customer_address_city,
        state: reorderData.address?.customer_address_state,
        zipcode: reorderData.address?.customer_address_zip,
      },
    });
  }

  /**
   * Get the upsell tests from the api and then initialize the form with the checkboxes.
   */
  private loadRecommendedTests(): void {
    const testIds = this.storageService.tests.map((test) => test.id);

    if (!testIds.length) {
      return;
    }

    this.loadingService.toggleLoader(true);
    this.orderService.getOrderUpsells(testIds).subscribe({
      next: (upsells) => this.handleSuccessfulGetOrderUpsells(upsells),
      error: () => this.loadingService.toggleLoader(false),
    });
  }

  /**
   * Handles Successful Get Order Upsells by initializing the form with the upsell tests.
   *
   * @param {OrderUpsell[]} upsells Array of upsells
   */
  private handleSuccessfulGetOrderUpsells(upsells: OrderUpsell[]): void {
    this.upsellTests = this.sortUpsellByPrice(upsells);
    this.initUpsellForm();
    this.loadingService.toggleLoader(false);
  }

  /**
   * Returns upsell sorted by price
   *
   * @param {OrderUpsell[]} upsells Array of upsells unsorted
   */
  private sortUpsellByPrice(upsells: OrderUpsell[]): OrderUpsell[] {
    return upsells.sort((a, b) => b.upsell_price - a.upsell_price);
  }

  /**
   * It returns the terms of service page link based on the domain
   */
  get tosLinkAddress(): string {
    return this.config.siteUrls.termsOfService;
  }

  /**
   * It shows whether it is STDcheck domain or not.
   */
  get isSTDCheckDomain(): boolean {
    return this.domainService.isSTDCheckDomain();
  }

  /**
   * Get if it is a phone order.
   */
  get isPhoneOrder(): boolean {
    return this.storageService.orderType === 'phone';
  }

  /**
   * Place an upsell order when there is any selected.
   *
   * @param { Upsell } upsell The upsell test information
   * @param { PaymentTypes } paymentType The payment type to be used for the upsell order
   */
  placeUpsellOrder(upsell: Upsell, paymentType: PaymentTypes): void {
    this.orderService.legacyStoreUpsell(new LegacyUpsellStoreRequest(upsell, paymentType)).subscribe({
      next: this.handleSuccessfulStoreUpsell.bind(this, upsell),
      error: (error) => {
        this.upsellPaymentFailed = true;
        this.changeDetectorRef.detectChanges();
        this.handleRequestError(error, false);
      },
    });
  }

  /**
   * Adds an upsell to the order, making the payment with the information coming from the upsell payment form.
   */
  submitUpsellPayment(): void {
    this.placeOrderComponent.processing = true;
    const upsell = this.getSelectedUpsellTest();
    this.orderService
      .legacyStoreUpsell(
        new LegacyUpsellStoreRequest(
          upsell,
          undefined,
          this.upsellTestsForm.get('payment').value,
          this.storageService.patient.name
        )
      )
      .subscribe({
        next: this.handleSuccessfulStoreUpsell.bind(this, upsell),
        error: (error) => {
          this.placeOrderComponent.processing = false;
          this.handleRequestError(error);
        },
      });
  }

  /**
   * Handler for the updateAddress request when it succeeds
   */
  handleSuccessfulOrderAddressUpdate(): void {
    this.storageService.addressComplete = true;
    this.formService.disableAllControls(this.addressContactInformation);
    const upsell = this.getSelectedUpsellTest();
    if (upsell) {
      return this.placeUpsellOrder(
        upsell,
        this.storageService.paymentType === PaymentTypes.PayLater ? PaymentTypes.PayLater : PaymentTypes.Recharge
      );
    }

    this.continueOrderingWorkflow();
  }

  /**
   * Handler for the StoreUpsell request when it succeeds.
   *
   * @param { Upsell }              upsell              the upsell tests that was successfully placed
   * @param { LegacyUpsellStoreResponse } upsellStoreResponse the successful response object
   */
  handleSuccessfulStoreUpsell(upsell: Upsell, upsellStoreResponse: LegacyUpsellStoreResponse): void {
    this.storageService.paymentType = this.upsellTestsForm.get('payment').value.method;
    this.orderService.saveUpsellTestInStorage(this.upsellSelected);
    if (upsellStoreResponse.transaction_id) {
      this.storageService.upsell = [upsellStoreResponse.transaction_id];
    }

    this.storageService.datalayerPool = [upsell];
    this.continueOrderingWorkflow();
  }

  /**
   * General purpose error handler for failing requests
   *
   * @param { HttpErrorResponse } error The response error object
   * @param { boolean } setSubmissionErrors Whether to set the submission errors or not
   */
  handleRequestError(error: HttpErrorResponse, setSubmissionErrors: boolean = true): void {
    this.processing = false;
    this.submissionErrors = setSubmissionErrors ? this.errorHandlerService.handleResponseError(error) : [];
  }

  /**
   * Handle upsell selected event by setting it in the component.
   *
   * @param {OrderUpsell} upsell the selected upsell
   */
  handleUpsellSelectedEvent(upsell: OrderUpsell): void {
    if (!this.upsellSelected) {
      this.upsellTestsForm.setControl('payment', this.formService.getNewPaymentForm());
    }

    this.upsellSelected = upsell;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Continue the ordering workflow.
   */
  continueOrderingWorkflow(): void {
    this.orderService.showOrderBetterLab().subscribe({
      next: (response) => {
        this.storageService.betterLab = response.has_better_lab;
        this.router.navigate([this.navigationService.getNextNavigationUrl()]);
      },
      error: () => this.router.navigate([this.navigationService.getNextNavigationUrl()]),
    });
  }

  /**
   * Update patient phone in storage
   */
  private readonly updatePatientPhoneInStorageIfNonEmpty = (): void => {
    const phone = this.addressContactInformation.get('phone.number').value;
    if (phone) {
      this.storageService.patient = { ...this.storageService.patient, phone };
    }
  };
}
