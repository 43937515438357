import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { OrderUpsell } from '@models/order-upsell';
import { TransactionResponse } from '@models/transaction-response';
import { ErrorHandlerService } from '@services/error-handler.service';
import { FormService } from '@services/form.service';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';
import { SessionStorageService } from '@services/session-storage.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';

@Component({
  selector: 'app-consultation-upsell-payment-card',
  templateUrl: './consultation-upsell-payment-card.component.html',
  styleUrl: './consultation-upsell-payment-card.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationUpsellPaymentCardComponent implements OnInit {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;

  processing: boolean = false;
  upsellPaymentForm: FormGroup;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private navigationService: NavigationService,
    private orderService: OrderService,
    private sessionStorageService: SessionStorageService,
    private treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.initializePaymentForm();
  }

  /**
   * Gets the upsells array with the price.
   *
   * @returns {OrderUpsell[]} the upsells array
   */
  get upsells(): OrderUpsell[] {
    return this.treatmentConsultationQuestionnaireService.getUpsellsWithPrice();
  }

  /**
   * Gets the total price of the selected upsells in USD.
   *
   * @returns {number} the total price in USD
   */
  get upsellTotalPriceInUsd(): number {
    return this.upsells.reduce((acc, upsell) => acc + upsell.upsell_price, 0) / 100;
  }

  /**
   * Initiates the upsell submission process and handles the response or error accordingly.
   */
  submitUpsells(): void {
    this.processing = true;
    const orderUpsells = this.treatmentConsultationQuestionnaireService.getUnorderedUpsells();
    const upsellChargeAmount = orderUpsells.reduce((acc, upsell) => acc + upsell.upsell_price, 0);
    const paymentRequest = this.orderService.getStorePaymentRequest(
      this.sessionStorageService.transactionId,
      this.upsellPaymentForm.get('payment').value,
      upsellChargeAmount,
      this.sessionStorageService.patient.name
    );

    this.orderService.submitUpsells(orderUpsells, paymentRequest, this.sessionStorageService.hash).subscribe({
      next: (response: TransactionResponse) => {
        if (this.sessionStorageService.upsells.includes(UpsellSlugs.PartnerTreatment)) {
          this.storePartnerEmail(response);

          return;
        }

        this.handleUpsellSuccess(response);
      },
      error: (err: any) => this.handleUpsellError(err),
    });
  }

  /**
   * Stores the partner email in the order service and handles the response or error accordingly.
   *
   * @param {TransactionResponse} response the response from the order payment service
   */
  private storePartnerEmail(response: TransactionResponse): void {
    this.orderService
      .storePartnerEmail(
        this.sessionStorageService.partnerEmail,
        this.sessionStorageService.transactionId,
        this.sessionStorageService.hash
      )
      .subscribe({
        next: () => this.handleUpsellSuccess(response),
        error: () => this.handleUpsellSuccess(response),
      });
  }

  /**
   * Handles an error response from the upsell store service. Updates error messages accordingly.
   *
   * @param {any} err the error returned from the upsell store request
   */
  private handleUpsellError(err: any): void {
    this.processing = false;
    this.placeOrderComponent.submissionErrors = this.errorHandlerService.handleResponseError(err);
  }

  /**
   * Handles a successful upsell response. Updates session storage and navigates to the next page.
   *
   * @param {TransactionResponse} response the response from the order payment service containing the transaction ID
   */
  private handleUpsellSuccess(response: TransactionResponse): void {
    if (response?.transaction_id) {
      this.sessionStorageService.upsell = [response.transaction_id];
    }

    this.processing = false;
    this.navigationService.navigateToNextConsultationRequestPage(this.sessionStorageService.treatmentType);
  }

  /**
   * Initializes the payment form.
   */
  private initializePaymentForm(): void {
    this.upsellPaymentForm = this.formBuilder.group({
      payment: this.formService.getNewPaymentForm(),
    });
  }
}
