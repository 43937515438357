import { Address } from '@models/address';
import { Patient } from '@models/patient';

export class ShippingAddressStoreRequest {
  first_name: string;
  last_name: string;
  street: string;
  city: string;
  state_id: number;
  zip_code: string;
  phone_number: string;
  test_ids?: number[];

  constructor(address: any, patient: Patient, testIds: number[] = null) {
    this.street = address.streetAddress || address.customer_address_street;
    this.city = address.city || address.customer_address_city;
    this.state_id = Address.statesIds[address.state || address.customer_address_state];
    this.zip_code = address.zipcode || address.customer_address_zip;
    this.first_name = patient.name;
    this.last_name = patient.last_name;
    this.phone_number = patient.phone;
    this.test_ids = testIds;
  }
}
