import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationPharmacyStateGuard implements CanActivate {
  private readonly PUERTO_RICO_STATE = 'PR';

  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private navigation: NavigationService
  ) {}

  /**
   * Determines whether the current route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route the route to be activated
   * @param {RouterStateSnapshot} state the current router state
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.sessionStorage.pharmacy?.state !== this.PUERTO_RICO_STATE) {
      return true;
    }

    const nextPageUrl = await this.navigation.getConsultationRequestNextPageUrl(
      this.sessionStorage.treatmentType,
      state.url
    );

    return this.router.createUrlTree([nextPageUrl]);
  }
}
