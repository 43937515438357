// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
@media screen and (max-width: 768px) {
  app-card .card-header.is-size-4-mobile {
    font-size: 1.25rem !important;
  }
}
app-card .card-header.numbered-header::before {
  counter-increment: section;
  content: counter(section) ". ";
}

app-stdcheck-layout app-consultation-upsell-payment-card app-card > .card {
  border: solid 1px rgba(19, 21, 22, 0.25) !important;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02) !important;
}

app-healthlabs-layout app-consultation-upsell-payment-card app-card > .card {
  border: solid 1px #e2e2e2 !important;
}
app-healthlabs-layout app-consultation-upsell-payment-card app-card > .card .card-content {
  background-color: white;
  border-radius: 0.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
